import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import firebase from "gatsby-plugin-firebase"
import Particles from "react-particles-js"

const Certificate = (props) => {
    const [data, setData] = useState(undefined)
    if (props.location.search) {
        const refId = props.location.search.split('=')[1]
        useEffect(() => {
            if (firebase) {
                const db = firebase.firestore()
                db.collection('results').doc(refId).get().then((snap) => {
                    console.log(snap.data())
                    if (snap.data()) {
                        setData(snap.data())
                    }
                    else {
                        setData(false)
                    }

                })
            }
        }, [])
    }
    else {
        useEffect(() => {
            setData(false)
        }, [])
    }
    return (
        <div>
            {data === undefined &&
                <div className='text-center'>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {data &&
                <div className='text-center'>
                    <h1>{data.fullName}</h1>
                    <h4>
                        ได้รับรางวัล&nbsp;
                    <span style={{ fontWeight: 500 }}>
                            {data.medal === 'เหรียญทอง' && <span className='text-gold'>{data.medal}</span>}
                            {data.medal === 'เหรียญเงิน' && <span className='text-silver'>{data.medal}</span>}
                            {data.medal === 'เหรียญทองแดง' && <span className='text-bronze'>{data.medal}</span>}
                            {data.medal === 'ชมเชย' && <span className='text-rainbow'>{data.medal}</span>}
                        </span>
                        <br />
                        จากการแข่งขัน Thailand Computing Olympiad 2020<br />
                        ระหว่างวันที่ 7-8 พฤศจิกายน พ.ศ. 2563
                    </h4>
                </div>}
            {data === false &&
                <div className='text-center alert alert-danger'><h5 className='mb-0'>ไม่พบข้อมูลผู้เข้าร่วมการแข่งขัน</h5></div>
            }
        </div>
    )
}

const VerifyPage = (props) => {
    console.log(props.location)
    return (
        <Layout>
            <SEO title="THACO | Verify" />
            <Header page="contact" />
            <section className="d-flex pb-5 text-white" style={{ paddingTop: 120, minHeight: '100vh', alignItems: 'center' }}>
                <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                    <Particles height="100%" />
                </div>
                <div className="container" style={{ maxWidth: 800 }}>
                    <h2 className="mb-5 text-center">
                        <span className="bg-dark px-2 py-1">ตรวจสอบผลการแข่งขัน</span>
                    </h2>
                    <Certificate location={props.location} />
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default VerifyPage
